<template>
  <form
    class="LoginForm"
    @submit.prevent="onSignInClick">
    <InputField
      icon="user"
      name="email"
      type="email"
      :disableAutoSuggest="true"
      :placeholder="mixWB('EMAIL')"
      :value="email"
      @on-update='onFieldUpdate' />
    <InputField
      icon="password"
      type="password"
      name="password"
      :placeholder="mixWB('PASSWORD')"
      :value="password"
      @on-update='onFieldUpdate' />
    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>
    <Button
      type="submit"
      :text="mixWB('SIGN_IN')"
      :isLoading="isLoading" />
  </form>
</template>

<script>
import { auth } from '@/firebase/init'
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import { getErrorMessage } from '@/firebase/errors'

export default {
  data() {
    return {
      email: '',
      password: '',
      errorText: '',
      isLoading: false,
    }
  },
  methods: {
    onSignInClick() {
      if (this.isLoading) return

      this.isLoading = true
      auth.signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.errorText = getErrorMessage(err.code, err.message)
        })
    },
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value
    },
  },
  components: {
    InputField,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem
</style>
